@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,"DM Sans", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "DM Sans",source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
