@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
.header{
    min-height: 80px;
    max-height: 80px;
    background: #fff !important;;
    font-family: 'DM Sans', sans-serif;

}
.toolbar{
    width: 80%;
    margin: auto;
    font-family: 'DM Sans', sans-serif;
}
.logo {
    top:0;
    bottom:0;
    left:0;
    right:0;
    height: 60px;
    padding: 30px 0px;
    max-height: 60px;
}
.navigation-bar{
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 40px;
    font-family: 'DM Sans', sans-serif;
    float: right;
}

.navigation-bar button{
    color: Red
    
}