@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,"DM Sans", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "DM Sans",source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header{
    min-height: 80px;
    max-height: 80px;
    background: #fff !important;;

}
.toolbar{
    width: 80%;
    margin: auto;
}
.logo {
    top:0;
    bottom:0;
    left:0;
    right:0;
    height: 60px;
    padding: 30px 0px;
    max-height: 60px;
}
.navigation-bar{
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 40px;
    float: right;
}

.navigation-bar button{
    color: Red
}
.header{
    min-height: 80px;
    max-height: 80px;
    background: #fff !important;;
    font-family: 'DM Sans', sans-serif;

}
.toolbar{
    width: 80%;
    margin: auto;
    font-family: 'DM Sans', sans-serif;
}
.logo {
    top:0;
    bottom:0;
    left:0;
    right:0;
    height: 60px;
    padding: 30px 0px;
    max-height: 60px;
}
.navigation-bar{
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding: 40px;
    font-family: 'DM Sans', sans-serif;
    float: right;
}

.navigation-bar button{
    color: Red
    
}
